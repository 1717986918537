import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import Content, { HTMLContent } from "../../components/Content";
import {
  Main,
  Section,
  Wrap,
  SEOTitle,
  BorderedHeading,
} from "../../common-styles";

export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <Main>
      <SEOTitle>{title}</SEOTitle>
      <Section color="mute" paddingBottom>
        <Wrap narrow>
          <BorderedHeading>{title}</BorderedHeading>
          <PageContent className="content" content={content} />
        </Wrap>
      </Section>
    </Main>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
